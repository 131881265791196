import store from "@/store";

export function handleDcbGameRedirects(encryptedMsisdn) {
    const {gameUrl, gameTitle} = store.state.brandConfig;
    if (gameTitle === 'Quizinc') {
        window.location = `${gameUrl}/optin/service/4070?cid=100920&bc_id=63&acq-channel=app&optin-channel&?e-msisdn=${encryptedMsisdn}`;
    } else {
        window.location = `${gameUrl}/?e-msisdn=${encryptedMsisdn}`;
    }
}
